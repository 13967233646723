import { useDispatch, useSelector } from 'react-redux';
import {
    IRSSearch,
    selectRS,
    goTo,
} from '~/src/store/realtySecondary/realtySecondarySlice';
import React, { useState, BaseSyntheticEvent, useEffect } from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    InputGroup,
    Row,
    ToggleButton,
} from 'react-bootstrap';
import { useQuery } from '~/src/helpers/hooks';
import { selectAuth } from '~/src/store/authSlice';
import { selectAgency } from '~/src/store/agencySlice';
import Checkbox from '../../../Common/FormFilter/Checkbox';
import Select, { MultiValue } from 'react-select';
import {
    ICombineHousingLivingAreaData,
    selectlivingArea,
} from '~/src/store/livingAreaSlice';

const SearchHiddenForm = ({
    show,
    formFilter,
    setFormFilter,
}: THiddenSearchFormProps): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();
    const { search } = useSelector(selectRS);
    const { me } = useSelector(selectAuth);
    const { xmlFeeds } = useSelector(selectAgency);
    const { combineHousingLivingAreaData } = useSelector(selectlivingArea);

    const [showMore, setShowMore] = useState<boolean>(false);

    const agents = me?.agencyInfo?.agencyAgents?.map(({ fullname, uid }) => {
        return {
            value: uid,
            label: fullname,
        };
    });

    const onSelectChange = (field: string, o: { label: string; value: string }) => {
        dispatch(
            setFormFilter({ ...formFilter, [field]: o?.value ?? null })
        );
    };

    const handleChangeValue = (e: BaseSyntheticEvent) => {
        dispatch(
            setFormFilter({ ...formFilter, [e.target.name]: e.target.value })
        );
    };

    const handleChangeHousingLivingArea = (
        items: MultiValue<ICombineHousingLivingAreaData>,
    ) => {
        const housingValue = [];
        const livingArea = [];
        items.forEach((item, index) => {
            if (item.type === 'housing') {
                housingValue.push(String(item.value));
            } else {
                livingArea.push(item.label);
            }
        });
        dispatch(
            setFormFilter({
                ...formFilter,
                housingEstateId: housingValue,
                livingArea: livingArea,
            })
        );
    };

    const filterValuesHousingLivingArea = () => {
        if (!combineHousingLivingAreaData) return [];
        // привести к массиву в любом случае
        const housingEstateArray = Array.isArray(formFilter?.housingEstateId)
            ? formFilter.housingEstateId
            : formFilter?.housingEstateId
                ? [String(formFilter.housingEstateId)]
                : [];

        const livingAreaArray = Array.isArray(formFilter?.livingArea)
            ? formFilter.livingArea
            : formFilter?.livingArea
                ? [formFilter.livingArea]
                : [];

        const data = combineHousingLivingAreaData.filter(curr =>
            housingEstateArray.some(id => id === String(curr.value)) ||
            livingAreaArray.some(area => area === curr.label));

        return data;
    };

    const changeSelectSerch = (option, inputValue) => {
        const normalizedOptionLabel = option.label
            .toLowerCase()
            .replace('е', 'ё');
        const normalizedInputValue = inputValue.toLowerCase().replace('е', 'ё');
        return normalizedOptionLabel.includes(normalizedInputValue);
    };

    const handleChangeCheckboxValue = (e: BaseSyntheticEvent) => {
        dispatch(
            setFormFilter({
                ...formFilter,
                [e.target.name]: formFilter[e.target.name] == '1' ? null : '1',
            })
        );
    };

    const handleChangeValueNumberValidator = (e: BaseSyntheticEvent) => {
        dispatch(
            setFormFilter({
                ...formFilter,
                [e.target.name]: e.target.value.replaceAll(/\D/g, ''),
            })
        );
    };

    const handleChangePrice = (e: BaseSyntheticEvent) => {
        let value;
        if (e.target.value.length > 0) {
            value = e.target.value.replaceAll(/\D/g, '');
            value = new Intl.NumberFormat('ru-RU').format(value);
        } else {
            value = e.target.value;
        }

        dispatch(
            setFormFilter({ ...formFilter, [e.target.name]: value })
        );
    };

    const handleShowMoreFilter = () => {
        setShowMore(!showMore);
    };

    if (show) {
        return (
            <>
                {me && me.agencyInfo ? (
                    <Row>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    <strong>Агенты</strong>
                                </Form.Label>
                                <Select
                                    name='agentUid'
                                    options={agents}
                                    value={agents.filter(
                                        (r) => formFilter.agentUid == r.value,
                                    )}
                                    onChange={(o) =>
                                        onSelectChange('agentUid', o)
                                    }
                                    isClearable
                                    placeholder='Агент'
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    <strong>Группы</strong>
                                </Form.Label>
                                <Form.Select
                                    onChange={handleChangeValue}
                                    size='sm'
                                    name='agencyGroupId'
                                    value={formFilter.agencyGroupId || 0}
                                >
                                    {me.agencyInfo?.agencyGroups?.map(
                                        ({ title, id }, index) => {
                                            if (index === 0) {
                                                return (
                                                    <React.Fragment key={id}>
                                                        <option value=''></option>
                                                        <option
                                                            key={id}
                                                            value={`${id}`}
                                                        >
                                                            {title}
                                                        </option>
                                                    </React.Fragment>
                                                );
                                            } else return (
                                                <option
                                                    key={id}
                                                    value={`${id}`}
                                                >
                                                    {title}
                                                </option>
                                            );
                                        },
                                    )}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                ) : null}
                {me && me.agencyInfo && (
                    <Row>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    <strong>Выгружается</strong>
                                </Form.Label>
                                <Form.Select
                                    onChange={handleChangeValue}
                                    size='sm'
                                    name='xmlFeedId'
                                    value={formFilter.xmlFeedId}
                                >
                                    {xmlFeeds?.map(({ title, id }, index) => {
                                        if (index === 0) {
                                            return (
                                                <React.Fragment key={id}>
                                                    <option value=''></option>
                                                    <option value={`${id}`}>
                                                        {title}
                                                    </option>
                                                </React.Fragment>
                                            );
                                        } else return (
                                            <option
                                                key={id}
                                                value={`${id}`}
                                            >
                                                {title}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col></Col>
                    </Row>
                )}
                <Row>
                    <Col sm='3'>
                        <Form.Group className='mb-2'>
                            <Form.Label>
                                <strong>ID</strong>
                            </Form.Label>
                            <Form.Control
                                type='text'
                                size='sm'
                                name='id'
                                value={formFilter?.id || ''}
                                onChange={handleChangeValue}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm='3'>
                        {
                            (me && me?.agencyInfo) &&
                            <Form.Group className='mb-2'>
                                <Form.Label>
                                    <strong>Номер продавца</strong>
                                </Form.Label>
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    name='sellerPhone'
                                    value={formFilter?.sellerPhone || ''}
                                    onChange={handleChangeValue}
                                />
                            </Form.Group>
                        }
                    </Col>
                    <Col sm='3'>
                        <Form.Group className='mb-2'>
                            <Form.Label>
                                <strong>Этажность</strong>
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    name='houseStoreyMin'
                                    value={formFilter?.houseStoreyMin || ''}
                                    onChange={handleChangeValueNumberValidator}
                                />
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    name='houseStoreyMax'
                                    value={formFilter?.houseStoreyMax || ''}
                                    onChange={handleChangeValueNumberValidator}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='mb-2'>
                            <Form.Label></Form.Label>
                        </Form.Group>
                        <ButtonGroup>
                            <ToggleButton
                                size='sm'
                                id='storey-spec-btn-not-first'
                                type='checkbox'
                                variant={
                                    formFilter.storeyNotFirst === '1'
                                        ? 'primary'
                                        : 'outline-primary'
                                }
                                name='storeyNotFirst'
                                value='1'
                                checked={formFilter.storeyNotFirst === '1'}
                                onChange={handleChangeCheckboxValue}
                            >
                                Не первый
                            </ToggleButton>
                            <ToggleButton
                                size='sm'
                                id='storey-spec-btn-not-last'
                                type='checkbox'
                                variant={
                                    formFilter.storeyNotLast === '1'
                                        ? 'primary'
                                        : 'outline-primary'
                                }
                                name='storeyNotLast'
                                value='1'
                                checked={formFilter.storeyNotLast === '1'}
                                onChange={handleChangeCheckboxValue}
                            >
                                Не последний
                            </ToggleButton>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm='3'>
                        <Form.Group className='mb-2'>
                            <Form.Label>
                                <strong>Адрес</strong>
                            </Form.Label>
                            <Form.Control
                                type='text'
                                size='sm'
                                name='address'
                                value={formFilter?.address || ''}
                                onChange={handleChangeValue}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm='3'>
                        <Form.Group className='mb-2'>
                            <Form.Label>
                                <strong>Этаж</strong>
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    name='storeyMin'
                                    value={formFilter?.storeyMin || ''}
                                    onChange={handleChangeValueNumberValidator}
                                />
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    name='storeyMax'
                                    value={formFilter?.storeyMax || ''}
                                    onChange={handleChangeValueNumberValidator}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='mb-1'>
                            <Form.Label>
                                <strong>Высота потолков</strong>
                            </Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='ceilingMin'
                                        value={formFilter?.ceilingMin || ''}
                                        onChange={handleChangeValue}
                                        placeholder='от'
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='ceilingMax'
                                        value={formFilter?.ceilingMax || ''}
                                        onChange={handleChangeValue}
                                        placeholder='до'
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <>Жилой комплекс или район</>
                            </Form.Label>
                            <Select
                                name='area'
                                isMulti
                                options={combineHousingLivingAreaData}
                                value={filterValuesHousingLivingArea() || []}
                                placeholder='Выберите ЖК, район'
                                onChange={handleChangeHousingLivingArea}
                                filterOption={changeSelectSerch}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='mb-1'>
                            <Form.Label>
                                <strong>Квартал </strong>
                            </Form.Label>
                            <Row>
                                <Col>
                                    <Form.Select
                                        name='houseBuiltQuarterMin'
                                        size='sm'
                                        value={
                                            formFilter?.houseBuiltQuarterMin ||
                                            ''
                                        }
                                        onChange={handleChangeValue}
                                        placeholder='от'
                                    >
                                        {Array(5)
                                            .fill(1)
                                            .map((_, index) =>
                                                index == 0 ? (
                                                    <option
                                                        key={`houseBuiltQuarterMin-${index}`}
                                                        value={null}
                                                    >
                                                        от
                                                    </option>
                                                ) : (
                                                    <option
                                                        key={`houseBuiltQuarterMin-${index}`}
                                                        value={index}
                                                    >
                                                        {index}
                                                    </option>
                                                ),)}
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Select
                                        name='houseBuiltQuarterMax'
                                        size='sm'
                                        value={
                                            formFilter?.houseBuiltQuarterMax ||
                                            ''
                                        }
                                        onChange={handleChangeValue}
                                        placeholder='до'
                                    >
                                        {Array(5)
                                            .fill(1)
                                            .map((_, index) =>
                                                index == 0 ? (
                                                    <option
                                                        key={`houseBuiltQuarterMin-${index}`}
                                                        value={null}
                                                    >
                                                        до
                                                    </option>
                                                ) : (
                                                    <option
                                                        key={`houseBuiltQuarterMin-${index}`}
                                                        value={index}
                                                    >
                                                        {index}
                                                    </option>
                                                ),)}
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className='mb-1'>
                            <Form.Label>
                                <strong>Цена</strong>
                            </Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='priceMin'
                                        value={formFilter?.priceMin || ''}
                                        onChange={handleChangePrice}
                                        placeholder='от'
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='priceMax'
                                        value={formFilter?.priceMax || ''}
                                        onChange={handleChangePrice}
                                        placeholder='до'
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group className='mb-1'>
                            <Form.Label>
                                <strong>Общая площадь</strong>
                            </Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='spaceTotalMin'
                                        value={formFilter?.spaceTotalMin || ''}
                                        onChange={handleChangeValue}
                                        placeholder='от'
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='spaceTotalMax'
                                        value={formFilter?.spaceTotalMax || ''}
                                        onChange={handleChangeValue}
                                        placeholder='до'
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='mb-2'>
                            <Form.Label>
                                {formFilter.realtyType == 'primary' ? (
                                    <strong>
                                        Год ввода дома в эксплуатацию
                                    </strong>
                                ) : (
                                    <strong>Год постройки</strong>
                                )}
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    name='houseBuiltMin'
                                    value={formFilter?.houseBuiltMin || ''}
                                    onChange={handleChangeValueNumberValidator}
                                    placeholder='от'
                                />
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    name='houseBuiltMax'
                                    value={formFilter?.houseBuiltMax || ''}
                                    onChange={handleChangeValueNumberValidator}
                                    placeholder='до'
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className='mb-1'>
                            <Form.Label>
                                <strong>Комнат</strong>
                            </Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='roomsMin'
                                        value={formFilter?.roomsMin || ''}
                                        onChange={handleChangeValue}
                                        placeholder='от'
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='roomsMax'
                                        value={formFilter?.roomsMax || ''}
                                        onChange={handleChangeValue}
                                        placeholder='до'
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
                {showMore ? (
                    <React.Fragment>
                        <Row>
                            <Col>
                                <Form.Group className='mb-1'>
                                    <Form.Label>
                                        <strong>Жилая площадь</strong>
                                    </Form.Label>
                                    <Row>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                size='sm'
                                                name='spaceLivingMin'
                                                value={
                                                    formFilter?.spaceLivingMin ||
                                                    ''
                                                }
                                                onChange={handleChangeValue}
                                                placeholder='от'
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                size='sm'
                                                name='spaceLivingMax'
                                                value={
                                                    formFilter?.spaceLivingMax ||
                                                    ''
                                                }
                                                onChange={handleChangeValue}
                                                placeholder='до'
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        <strong>Тип комнат</strong>
                                    </Form.Label>
                                    <Form.Select
                                        name='roomType'
                                        size='sm'
                                        value={
                                            formFilter.roomType || 'all' || 0
                                        }
                                        onChange={handleChangeValue}
                                        placeholder='Тип комнат'
                                    >
                                        <option>Укажите тип комнат</option>
                                        <option value='all'>Все</option>
                                        <option value='isolated'>
                                            Изолированные
                                        </option>
                                        <option value='adjacent'>
                                            Смежные
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        <strong>Материал дома</strong>
                                    </Form.Label>
                                    <Form.Select
                                        name='houseMaterial'
                                        size='sm'
                                        value={
                                            formFilter.houseMaterial || 'all'
                                        }
                                        onChange={handleChangeValue}
                                        placeholder='Материал дома'
                                    >
                                        <option>Укажите материал</option>
                                        <option value='all'>Все</option>
                                        <option value='brick'>Кирпич</option>
                                        <option value='panel'>Панель</option>
                                        <option value='monolith'>
                                            Монолит
                                        </option>
                                        <option value='block'>Блочный</option>
                                        <option value='wood'>Деревянный</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-1'>
                                    <Form.Label>
                                        <strong>Площадь кухни</strong>
                                    </Form.Label>
                                    <Row>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                size='sm'
                                                name='spaceKitchenMin'
                                                value={
                                                    formFilter?.spaceKitchenMin ||
                                                    ''
                                                }
                                                onChange={handleChangeValue}
                                                placeholder='от'
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                size='sm'
                                                name='spaceKitchenMax'
                                                value={
                                                    formFilter?.spaceKitchenMax ||
                                                    ''
                                                }
                                                onChange={handleChangeValue}
                                                placeholder='до'
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        <strong>Ремонт</strong>
                                    </Form.Label>
                                    <Form.Select
                                        name='renovation'
                                        size='sm'
                                        value={
                                            formFilter.renovation || 'all' || -1
                                        }
                                        onChange={handleChangeValue}
                                        placeholder='Укажите ремонт'
                                    >
                                        <option>Укажите ремонт</option>
                                        <option value='all'>Все</option>
                                        <option value='standard'>
                                            Косметический
                                        </option>
                                        <option value='design'>
                                            Дизайнерский
                                        </option>
                                        <option value='no_decoration'>
                                            Требуется
                                        </option>
                                        <option value='euro'>Евро</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            {/* <Col>
                            <Checkbox
                                name='stainedGlass'
                                onChange={handleChangeCheckboxValue}
                                label='Витражные окна'
                                checked={formFilter.stainedGlass == '1'}
                            />
                        </Col> */}
                            <Col>
                                <Checkbox
                                    name='highflat'
                                    onChange={handleChangeCheckboxValue}
                                    label='Хайфлет'
                                    checked={formFilter.highflat == '1'}
                                />
                            </Col>
                            {/* <Col>
                            <Checkbox
                                name='kitchenLiving'
                                onChange={handleChangeCheckboxValue}
                                label='Кухня - гостиная'
                                checked={formFilter.kitchenLiving == '1'}
                            />
                        </Col> */}
                            {/* <Col>
                            <Checkbox
                                name='masterBedroom'
                                onChange={handleChangeCheckboxValue}
                                label='Мастер - спальня'
                                checked={formFilter.masterBedroom == '1'}
                            />
                        </Col> */}
                        </Row>
                        <Row>
                            {/* <Col>
                            <Checkbox
                                name='groundParking'
                                onChange={handleChangeCheckboxValue}
                                label='Наземная парковка'
                                checked={formFilter.groundParking == '1'}
                            />
                        </Col> */}
                            <Col>
                                <Checkbox
                                    name='undergroundParking'
                                    onChange={handleChangeCheckboxValue}
                                    label='Подземная парковка'
                                    checked={
                                        formFilter.undergroundParking == '1'
                                    }
                                />
                            </Col>
                            <Col>
                                <Checkbox
                                    name='isClosedArea'
                                    onChange={handleChangeCheckboxValue}
                                    label='Закрытая территория'
                                    checked={formFilter.isClosedArea == '1'}
                                />
                            </Col>
                            {/* <Col>
                            <Checkbox
                                name='underfloorHeatingSystem'
                                onChange={handleChangeCheckboxValue}
                                label='Теплый пол'
                                checked={
                                    formFilter.underfloorHeatingSystem ==
                                    '1'
                                }
                            />
                        </Col> */}
                        </Row>
                        <Row>
                            <Col>
                                <Checkbox
                                    name='withoutBankEncumbrance'
                                    onChange={handleChangeCheckboxValue}
                                    label='Без обременения банка'
                                    checked={
                                        formFilter.withoutBankEncumbrance == '1'
                                    }
                                />
                            </Col>
                            <Col>
                                <Checkbox
                                    name='terrace'
                                    onChange={handleChangeCheckboxValue}
                                    label='Терраса'
                                    checked={formFilter.terrace == '1'}
                                />
                            </Col>
                            {/* <Col>
                            <Checkbox
                                name='multilevel'
                                onChange={handleChangeCheckboxValue}
                                label='Многоуровневая'
                                checked={formFilter.multilevel == '1'}
                            />
                        </Col>
                        <Col>
                            <Checkbox
                                name='isAreaCctv'
                                onChange={handleChangeCheckboxValue}
                                label='Видеонаблюдение'
                                checked={formFilter.isAreaCctv == '1'}
                            />
                        </Col>
                        <Col>
                            <Checkbox
                                name='smartHouseSystem'
                                onChange={handleChangeCheckboxValue}
                                label='Система Умный дом'
                                checked={formFilter.smartHouseSystem == '1'}
                            />
                        </Col> */}
                        </Row>
                        <Row>
                            <Col>
                                <Checkbox
                                    name='withoutSellerMinors'
                                    onChange={handleChangeCheckboxValue}
                                    label='Без несовершеннолетних собственников'
                                    checked={
                                        formFilter.withoutSellerMinors == '1'
                                    }
                                />
                            </Col>
                            {/* <Col>
                            <Checkbox
                                name='isSchoolBeside'
                                onChange={handleChangeCheckboxValue}
                                label='Рядом школа'
                                checked={formFilter.isSchoolBeside == '1'}
                            />
                        </Col>
                        <Col>
                            <Checkbox
                                name='isPreschoolBeside'
                                onChange={handleChangeCheckboxValue}
                                label='Рядом д. сад'
                                checked={
                                    formFilter.isPreschoolBeside == '1'
                                }
                            />
                        </Col> */}
                            {/* <Col>
                            <Checkbox
                                name='viewApartment'
                                onChange={handleChangeCheckboxValue}
                                label='Видовая квартира'
                                checked={formFilter.viewApartment == '1'}
                            />
                        </Col> */}
                        </Row>
                        <Row>
                            <Col>
                                <Checkbox
                                    name='withoutUnderstatement'
                                    onChange={handleChangeCheckboxValue}
                                    label='Без занижения'
                                    checked={
                                        formFilter.withoutUnderstatement == '1'
                                    }
                                />
                            </Col>
                            {/* <Col>
                            <Checkbox
                                name='lowRiseEstate'
                                onChange={handleChangeCheckboxValue}
                                label='Малоэтажный ЖК'
                                checked={formFilter.lowRiseEstate == '1'}
                            />
                        </Col>
                        <Col>
                            <Checkbox
                                name='isProtectedArea'
                                onChange={handleChangeCheckboxValue}
                                label='Охраняемая территория'
                                checked={formFilter.isProtectedArea == '1'}
                            />
                        </Col> */}
                        </Row>
                        <Row>
                            {/* <Col>
                            <Checkbox
                                name='hasBesideMall'
                                onChange={handleChangeCheckboxValue}
                                label='Рядом ТЦ'
                                checked={formFilter.hasBesideMall == '1'}
                            />
                        </Col>
                        <Col>
                            <Checkbox
                                name='hasBesideWater'
                                onChange={handleChangeCheckboxValue}
                                label='Рядом водоем'
                                checked={formFilter.hasBesideWater == '1'}
                            />
                        </Col>
                        <Col>
                            <Checkbox
                                name='hasBesideForest'
                                onChange={handleChangeCheckboxValue}
                                label='Рядом лес'
                                checked={formFilter.hasBesideForest == '1'}
                            />
                        </Col>
                        <Col>
                            <Checkbox
                                name='hasBesidePark'
                                onChange={handleChangeCheckboxValue}
                                label='Рядом парк'
                                checked={formFilter.hasBesidePark == '1'}
                            />
                        </Col> */}
                        </Row>
                        {/* <Row>
                        <Col>
                            <Checkbox
                                name='isAreaSportGround'
                                onChange={handleChangeCheckboxValue}
                                label='Спортивная площадка'
                                checked={
                                    formFilter.isAreaSportGround == '1'
                                }
                            />
                        </Col>
                    </Row> */}
                    </React.Fragment>
                ) : null}
                <ButtonGroup>
                    <Button
                        className='mt-2'
                        type='submit'
                        variant='primary'
                        size='sm'
                    >
                        Найти
                    </Button>
                    <Button
                        variant='link'
                        size='sm'
                        onClick={handleShowMoreFilter}
                    >
                        {showMore
                            ? 'Скрыть подробный поиск'
                            : 'Подробный поиск'}
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    return null;
};

type THiddenSearchFormProps = {
    show: boolean;
    formFilter: IRSSearch;
    setFormFilter: React.Dispatch<React.SetStateAction<IRSSearch>>;
    toggleShowForm: () => void;
};

export default SearchHiddenForm;
