import queryString from 'query-string';
import * as api from '~/src/service/buyer';
import { create as createComment } from '~/src/service/comment';
import { IBuyer, IBuyerStatistics } from '../../service/buyer';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IResponseListData } from '../../interfaces/common';
import { redirect, setLoading as setAppLoading } from '../appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import dateFormat from 'dateformat';
import phoneFormatter from '~/src/helpers/phoneFormatter';
import { TLane } from './types';
import { errorNotify, successNotify } from '~/src/helpers/createNotify';

export const sliceName = 'buyerList';

interface IBuyerListSlice {
    items: IResponseListData<IBuyer>;
    item?: IBuyer;
    statistics?: IBuyerStatistics;
    board?: TLane[];
    search?: any;
    searchBoard?: any;
    errors?: any;
    navLink: string;
    baseLink: string;
    loading: boolean;
    sources?: { source: string; count: number }[];
    modalOpened: boolean;
    checkBuyers: number[];
}

const initialState = {
    items: {},
    board: { lanes: [] } as unknown as TLane[],
    statistics: {},
    search: {
        sort: 'cd_asc',
        limit: 25,
        page: 1,
        my: null,
        status: 'active',
    },
    searchBoard: {
        my: null,
    },
    checkBuyers: [],
    unloaded: null,
    loading: true,
    sources: [],
    navLink: '/buyer',
    baseLink: '/buyer',
    modalOpened: false,
};

const buyerListSlice: Slice<IBuyerListSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: IBuyerListSlice,
            action: TAnyAction<IResponseListData<IBuyer>>,
        ) => {
            state.items = action.payload;
        },
        set: (state: IBuyerListSlice, action: TAnyAction<IBuyer>) => {
            state.item = action.payload;
        },
        setStatistics: (state: IBuyerListSlice, action: TAnyAction<IBuyerStatistics>) => {
            state.statistics = action.payload;
        },
        setErrors: (state: IBuyerListSlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
        setSearch: (state: IBuyerListSlice, action: TAnyAction<any>) => {
            state.search = action.payload;
        },
        setSearchBoard: (state: IBuyerListSlice, action: TAnyAction<any>) => {
            state.searchBoard = action.payload;
        },
        setLoading: (state: IBuyerListSlice, action: TAnyAction<boolean>) => {
            state.loading = action.payload;
        },
        setSources: (
            state: IBuyerListSlice,
            action: TAnyAction<{ source: string; count: number }[]>,
        ) => {
            state.sources = action.payload;
        },
        setBoard: (state: IBuyerListSlice, action: TAnyAction<any>) => {
            state.board = action.payload;
        },
        setCheckBayers: (state: IBuyerListSlice, action: TAnyAction<any>) => {
            state.checkBuyers = action.payload;
        },
        setModalOpened: (
            state: IBuyerListSlice,
            action: TAnyAction<boolean>,
        ) => {
            state.modalOpened = action.payload;
        },
        setNavLink: (state: IBuyerListSlice, action: TAnyAction<string>) => {
            state.navLink = action.payload;
        },
    },
});

export const {
    set,
    setAll,
    setBoard,
    setErrors,
    setSearch,
    setSearchBoard,
    setLoading,
    setSources,
    setModalOpened,
    setNavLink,
    setStatistics,
    setCheckBayers
} = buyerListSlice.actions;

const prepareQueryParams = (queryParams: URLSearchParams = null) => {
    const obj = {};
    for (const key of queryParams.keys()) {
        if (queryParams.getAll(key).length > 1) {
            obj[key] = queryParams.getAll(key);
        } else {
            obj[key] = queryParams.get(key);
        }
    }
    return obj;
};

export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.all(queryParams);
        const obj = prepareQueryParams(queryParams);
        dispatch(setSearch(obj));
        dispatch(setAll(response));
        dispatch(setAppLoading(false));
    };
};

export const getStatistics = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            const response = await api.statistics(queryParams);
            dispatch(setStatistics(response));
        } catch (e) {
            console.warn(e);
        }
    };
};


export const prepareCardDescription = <T>(item: IBuyer, joined = true): T => {
    const _arr = [
        `КД: ${dateFormat(item.controlDate, 'dd.mm.yyyy')}`,
        phoneFormatter(`${item.contactPhone}`),
    ];

    if (item.fields?.priceFrom) {
        _arr.push(`Цена от: ${item.fields?.priceFrom}`);
    }
    if (item.fields?.priceTo) {
        _arr.push(`Цена до: ${item.fields?.priceTo}`);
    }
    if (item.fields?.spaceFrom) {
        _arr.push(`Площадь от: ${item.fields?.spaceFrom}`);
    }
    if (item.fields?.spaceTo) {
        _arr.push(`Площадь до: ${item.fields?.spaceTo}`);
    }

    return (joined ? _arr : _arr.join('\n')) as T;
};

const switchColumn = (data) => {
    const archive = data.splice(5, 1)[0];
    const deal = data.splice(5, 1)[0];
    data.push({
        ...deal,
        id: archive.id,
    });
    data.push({
        ...archive,
        id: deal.id,
    });
    return data;
};

const transform = (data: any) => {
    const ttitle = {
        today: 'Позвонить сегодня',
        tomorrow: 'Позвонить завтра',
        three: '3 дня и более',
        week: '7 дней и более',
        month: '30 дней и более',
        archive: 'Архив',
        deal: 'Сделка',
    };
    const newData = data.map(([date, items], index) => ({
        date,
        id: index,
        items: items.items
            .filter(
                (item) =>
                    (item.status === 'archive' && date === 'archive') ||
                    (item.status === 'deal' && date === 'deal') ||
                    (item.status === 'active' && date !== 'deal') ||
                    (item.status === 'active' && date !== 'archive'),
            )
            .map((item) => ({ ...item })),
        title: ttitle[date],
        count: items.count,
    }));
    return switchColumn(newData);
};

export const getBoard = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            dispatch(setLoading(true));
            const response = await api.getBoard(queryParams);
            if (response) {
                const modifedBoard = transform(Object.entries(response));
                const obj = prepareQueryParams(queryParams);
                dispatch(setSearchBoard(obj));
                dispatch(setNavLink(`/buyer?${queryParams.toString()}`));
                dispatch(setBoard(modifedBoard));
            }
        } finally {
            dispatch(setAppLoading(false));
            dispatch(setLoading(false));
        }
    };
};

export const create = (data: any) => {
    return async (dispatch: TAppDispatch): Promise<any> => {
        try {
            dispatch(set(data));
            dispatch(setLoading(true));
            const response = await api.create(data);
            if (!response) {
                dispatch(setErrors(response.errors));
            } else {
                return response;
            }
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            dispatch(setLoading(true));
            const data = await api.read(id);
            dispatch(set(data));
        } finally {
            dispatch(setAppLoading(false));
            dispatch(setLoading(false));
        }
    };
};

export const update = (id: number, data: any) => {
    return async (dispatch: TAppDispatch): Promise<any> => {
        try {
            dispatch(set(data));
            dispatch(setLoading(true));
            const response = await api.update(id, data);
            if (!response.success) {
                dispatch(setErrors(response.errors));
            } else {
                return response;
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const remove = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            dispatch(setLoading(true));
            const response = await api.remove(id);
            if (response && response.success) {
                dispatch(
                    setAll({
                        ...getState()[sliceName].items,
                        items: getState()[sliceName].items.items.filter(
                            (item: IBuyer) => item.id != id,
                        ),
                    }),
                );
            } else {
                dispatch(setErrors(response.errors));
            }
        } finally {
            dispatch(setAppLoading(false));
            dispatch(setLoading(false));
        }
    };
};

export const transfer = (id: number[], uid: string, name: string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        try {
            const response = await api.transfer(id, uid);
            if (response) {
                successNotify({
                    message: 'Добавлено',
                    title: 'Успешно!',
                });
                dispatch(setCheckBayers([]));
                dispatch(
                    setAll({
                        ...getState().buyerList.items,
                        items: getState().buyerList.items.items.map(
                            (item: IBuyer) => {
                                if (id.includes(item.id)) {
                                    return {
                                        ...item,
                                        agent: {
                                            ...item.agent,
                                            fullname: name
                                        }
                                    };
                                }
                                return item;
                            }
                        ),
                    }),
                );
                id.forEach(_id => {
                    const item = getState().buyerList.item;

                    if (item && item.id === _id) {
                        dispatch(set({
                            ...item,
                            agent: {
                                ...item.agent,
                                fullname: name
                            }
                        }));
                    }
                });
            }
        } catch (error) {
            errorNotify({
                message: 'Что-то пошло не так ):',
                title: 'Ошибка!',
            });
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const getPopularSources =
    () =>
        async (dispatch: TAppDispatch, getState: () => TState): Promise<void> => {
            if (getState().buyerList.sources?.length > 0) return;

            try {
                dispatch(setLoading(true));
                const response = await api.getPopularSources();
                if (response) {
                    dispatch(setSources(response));
                }
            } finally {
                dispatch(setLoading(false));
            }
        };

export const addCommentFromRow =
    (id: number, content: string) =>
        async (dispatch: TAppDispatch, getState: () => TState): Promise<void> => {
            try {
                dispatch(setLoading(true));
                const response = await createComment({
                    section: 'buyer',
                    entry: id,
                    content,
                });
                if (response) {
                    dispatch(
                        setAll({
                            ...getState().buyerList.items,
                            items: [
                                ...getState().buyerList.items.items.map((item) => {
                                    if (item.id == id) {
                                        return {
                                            ...item,
                                            lastComment: response,
                                        };
                                    }

                                    return item;
                                }),
                            ],
                        }),
                    );
                }
            } finally {
                dispatch(setLoading(false));
            }
        };

export const changeControlDateBoard = (id: number, data) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            const response = await api.changeControlDate(id, data);
            if (response && response.success) {
                dispatch(
                    setBoard(
                        getState().buyerList.board.map((data) => {
                            return {
                                ...data,
                                items: data.items.map((item) => {
                                    if (item.id === response.data.id) {
                                        return {
                                            ...item,
                                            controlDate:
                                                response.data.controlDate,
                                        };
                                    }
                                    return item;
                                }),
                            };
                        }),
                    ),
                );
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        }
    };
};
export const changeControlDate = (id: number, data) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            dispatch(setLoading(true));
            const response = await api.changeControlDate(id, data);
            if (response && response.success) {
                dispatch(
                    setAll({
                        ...getState().buyerList.items,
                        items: [
                            ...getState().buyerList.items.items.map((item) => {
                                if (item.id == id) {
                                    return {
                                        ...item,
                                        controlDate: response.data.controlDate,
                                    };
                                }
                                return item;
                            }),
                        ],
                    }),
                );
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
            dispatch(setLoading(false));
        }
    };
};

export const changeStatusBoard = (id: number, status: string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            const response = await api.changeStatus(id, status);
            if (response && response.success) {
                dispatch(
                    setBoard(
                        getState().buyerList.board.map((data) => {
                            return {
                                ...data,
                                items: data.items.map((item) => {
                                    if (item.id === response.data.id) {
                                        return {
                                            ...item,
                                            status: response.data.status,
                                        };
                                    }
                                    return item;
                                }),
                            };
                        }),
                    ),
                );
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        }
    };
};
export const changeStatus = (id: number, status: string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            dispatch(setLoading(true));
            const response = await api.changeStatus(id, status);
            if (response && response.success) {
                dispatch(
                    setAll({
                        ...getState().buyerList.items,
                        items: [
                            ...getState().buyerList.items.items.map((item) => {
                                if (item.id == id) {
                                    return {
                                        ...item,
                                        status: response.data.status,
                                    };
                                }
                                return item;
                            }),
                        ],
                    }),
                );
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
            dispatch(setLoading(false));
        }
    };
};
export const changeStatusItem = (id: number, status: string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            dispatch(setLoading(true));
            const response = await api.changeStatus(id, status);
            if (response && response.success) {
                dispatch(
                    set({
                        ...getState().buyerList.item,
                        status: response.data.status,
                    }),
                );
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
            dispatch(setLoading(false));
        }
    };
};

export const goTo =
    (params: Record<string, any>) =>
        (dispatch: TAppDispatch, getState: () => TState): void => {
            if (params.reset === true) {
                params = initialState.search;
            } else params = { ...getState()[sliceName].search, ...params };

            dispatch(
                redirect(
                    `/buyer/list?${queryString.stringify(params, { skipNull: true })}`,
                ),
            );
        };

export const goToBoard =
    (params: Record<string, any>) =>
        (dispatch: TAppDispatch, getState: () => TState): void => {
            if (params.reset === true) {
                params = initialState.searchBoard;
            } else params = { ...getState()[sliceName].searchBoard, ...params };

            dispatch(
                redirect(
                    `/buyer?${queryString.stringify(params, { skipNull: true })}`,
                ),
            );
        };

export const selectBuyerList = (state: TState): IBuyerListSlice => state[sliceName];

export default buyerListSlice.reducer;
